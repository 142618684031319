import * as React from 'react';

// @mui
import { Container } from "@mui/material";

// hooks
import useSettings from '../../hooks/useSettings';
// components
import DashboardLayout from '../../layouts/dashboard';
import Page from '../../components/Page';

import { NetworkDetail } from "../../sections";

// ----------------------------------------------------------------------

export default function PageNetworkDetail({networkId,networkLabel=undefined}) {
    const { themeStretch } = useSettings();

    if(networkLabel === undefined) networkLabel=networkId;

    return (
        <DashboardLayout>
            <Page title={`Network Detail: ${networkLabel}`}>
                <Container maxWidth={themeStretch ? false : 'xl'}>
                    <NetworkDetail networkId={networkId} networkLabel={networkLabel}/>
                </Container>
            </Page>
        </DashboardLayout>
    );
}
